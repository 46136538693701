<template>
    <div class="app-subscriber-footer">
        <div class="app-subscriber-footer__links">
            <a href="https://tipalti.com/contact-us/" target="_blank" class="app-subscriber-footer__links--link">{{ contact }}</a>
            <div class="app-subscriber-footer__links--separator">|</div>
            <a href="https://tipalti.com/privacy/" target="_blank" class="app-subscriber-footer__links--link">{{ privacy }}</a>
            <div class="app-subscriber-footer__links--separator">|</div>
            <a href="https://tipalti.zendesk.com/hc/en-us/requests/new" target="_blank" class="app-subscriber-footer__links--link">{{ support }}</a>
        </div>
        <div class="app-subscriber-footer__copyright">Tipalti © {{ year }}</div>
    </div>
</template>
<script>
import langConsts from '@/lang/en';

export default {
    name: 'AppSubscriberFooter',
    computed: {
        year() {
            return new Date().getFullYear();
        },
        contact() {
            return langConsts.contact;
        },
        privacy() {
            return langConsts.privacy;
        },
        support() {
            return langConsts.support;
        }
    }
};
</script>
<style lang="scss">
.app-subscriber-footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 0.625rem;
    margin: 1rem 1rem 1.75rem 1rem;

    &__copyright {
        font-weight: 700;
        line-height: 0.8rem;
        color: $outer-space;
        margin-top: 0.5rem;
    }
    &__links {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: baseline;

        &--link {
            font-weight: 400;
            line-height: 0.75rem;
            color: $cornflower-blue;
            text-decoration: none;
        }

        &--separator {
            color: $alto;
            font-size: 0.75rem;
            line-height: 0.875rem;
            font-weight: 700;
            margin: 0 0.4rem 0 0.4rem;
        }
    }
}
</style>
