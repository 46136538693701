<template>
    <div class="select-payer-entities">
        <div>
            <app-subscriber-header :title="payersTitle" />
            <div v-if="shouldDisplayPayers" class="select-payer-entities__contents">
                <payers-list :payers="userPayers" @payer-selected="payerEntitiesSubmit" />
            </div>
            <div v-else class="select-payer-entities__loading">
                <tip-spinner />
            </div>
        </div>
        <app-subscriber-footer v-if="shouldDisplayPayers" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import langConsts from '@/lang/en';
import loginConsts from '@/consts/loginConsts';
import AppSubscriberHeader from '@/components/AppSubscriber/AppSubscriberHeader';
import AppSubscriberFooter from '@/components/AppSubscriber/AppSubscriberFooter';
import PayersList from '@/components/PayerEntities/PayersList';

export default {
    name: 'SelectPayerEntities',
    components: {
        AppSubscriberHeader,
        AppSubscriberFooter,
        PayersList
    },
    data() {
        return {
            localIsLoading: false
        };
    },
    computed: {
        ...mapState('login', ['isLoading', 'userPayers', 'redirectUrl', 'isSubscribeAllowed', 'showErrorLogin']),

        isAnyLoadingStatus() {
            return this.isLoading || this.localIsLoading;
        },
        shouldDisplayPayers() {
            return !this.isAnyLoadingStatus && this.userPayers && this.userPayers.length > 1;
        },
        payersTitle() {
            return !this.shouldDisplayPayers ? '' : langConsts.titleSelectPayer;
        }
    },
    async created() {
        await this.canSubscribe();
        if (!this.showErrorLogin) {
            if (!this.isSubscribeAllowed) {
                router.replace(`${loginConsts.urls.baseSubscriberAppUrl}/${loginConsts.urls.urlPathMissingPermission}`);
            } else {
                await this.getPayersForCurrentLoginStateUser();

                if (this.userPayers && this.userPayers.length === 1) {
                    await this.callCreateAndSigninApplicativeUser({
                        payer: this.userPayers[0],
                        payerEntities: []
                    });
                }
            }
        }
    },
    methods: {
        ...mapActions('login', ['getPayersForCurrentLoginStateUser', 'createAndSigninApplicativeUser', 'canSubscribe']),
        async payerEntitiesSubmit(payerId) {
            this.localIsLoading = true;

            const selectedPayer = this.userPayers.find((x) => x.payerId === payerId);

            await this.callCreateAndSigninApplicativeUser({
                payer: selectedPayer,
                payerEntities: []
            });
        },
        async callCreateAndSigninApplicativeUser({ payer, payerEntities }) {
            await this.createAndSigninApplicativeUser({
                selectedPayerId: payer.payerId,
                selectedPayerEntityIdArray: payerEntities.map((x) => x.payerEntityId)
            });
            if (!this.showErrorLogin) {
                window.location.replace(this.redirectUrl);
            } else {
                this.localIsLoading = false;
            }
        }
    }
};
</script>

<style lang="scss">
.select-payer-entities {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__error {
        color: $outrageous-orange;
        margin-top: 1rem;
    }
    &__button {
        margin-top: 5rem;
    }
    &__contents {
        margin: 1.5rem 0.5rem 0 0;

        /* width */
        ::-webkit-scrollbar {
            width: 4px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: $gallery;
            border-radius: 3.75rem;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: rgba($daintree, 0.5);
            border-radius: 3.75rem;
        }
    }
    &__loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 11.875rem;
    }
}
</style>
