<template>
    <div class="payers-list">
        <div class="payers-list__left"></div>
        <div class="payers-list__center">
            <tip-button
                v-for="payer in payers"
                :key="payer.payerId"
                class="payers-list__payer-button"
                :type="accentButtonType"
                @click="notifyCaller(payer.payerId)"
            >
                <div>{{ payer.payerName }}</div>
                <div v-if="isSubscribed(payer)" class="payers-list__payer-button__container">
                    <font-awesome-icon class="payers-list__payer-button--checked" :icon="['fal', 'check-circle']" />
                    <div>{{ payerSubscribed }}</div>
                </div>
                <template v-slot:loading>
                    <font-awesome-icon class="fa-spin" :icon="['far', 'spinner']" />
                </template>
            </tip-button>
        </div>
        <div class="payers-list__right"></div>
    </div>
</template>
<script>
import langConsts from '@/lang/en';

export default {
    name: 'PayersList',
    props: {
        payers: {
            type: Array,
            required: true
        }
    },
    computed: {
        payerSubscribed() {
            return langConsts.payerSubscribed;
        },
        accentButtonType() {
            return 'accent';
        },
        payerSelectedEventName() {
            return 'payer-selected';
        }
    },
    methods: {
        notifyCaller(payerId) {
            this.$emit(this.payerSelectedEventName, payerId);
        },
        isSubscribed(payer) {
            return payer.isThirdPartyAppRegistered;
        }
    }
};
</script>
<style lang="scss" scoped>
.payers-list {
    display: flex;
    flex-direction: row;

    &__payer-button {
        margin-bottom: 1rem;

        &--checked {
            color: $caribbean-green;
            margin-right: 0.25rem;
        }
        &__container {
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 0.625rem;
            line-height: 0.815rem;
        }
    }
    &__right {
        min-width: 3.875rem;
    }
    &__left {
        min-width: 4.375rem;
    }
    //This one isn't BEM and we need this, that's why used scoped CSS
    &__center {
        width: 100%;
    }
    overflow-y: overlay;
    max-height: 23.5rem;
}
</style>
